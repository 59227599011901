import React from 'react';
import Body from '@sections/Subscribe';
import Layout from '@layouts/business';
import SEO from '@components/seo';

export default props => (
  <Layout>
    <SEO
      title="Оставить заявку | IMstream"
      description="Получите полный и ничем неограниченный доступ к музыкальному каталогу IMstream, состоящему из огромного количества композиций и жанров."
    />
    <Body {...props} />
  </Layout>
);

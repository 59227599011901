import React, { Fragment } from 'react';

import { br } from '@utils';
import BackgroundImage from './background';
import Form from './Form';
import PopUp from '@components/PopUp';
import FormWithPopUp from '@components/FormWithPopUp';
import postCurry from '@api/Mailer/post';

import styles from './styles.module.css';
import { title, subtitle, mail } from './data.json';

export default function({ location }) {
  const post = postCurry({
    subject:
      location.state && location.state.isSubscription ? mail.sub : mail.trial,
  });

  return (
    <Fragment>
      <BackgroundImage className={styles.container} style={{ flexGrow: '1' }}>
        <div className={styles.wrapper}>
          <b className={styles.header}>{title}</b>
          <p className={styles.subtitle}>{br(subtitle)}</p>
          <FormWithPopUp Form={Form} PopUp={PopUp} post={post} />
        </div>
      </BackgroundImage>
    </Fragment>
  );
}

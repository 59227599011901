import React from 'react';
import { Formik, Form } from 'formik';

import Yup from '@components/Form/Yup/variants/2';
import FieldWrapper from '@components/Form/FieldWrapper';
import { ErrorMessage2 } from '@components/Form/ErrorMessage';
import { Label1 } from '@components/Form/Label';
import Submit from '@components/Button/Submit/1';

import { createPropsFunc, options } from '@components/Form/Formik/';

import { Name, Email, Phone, Region, Message } from './Field';

import styles from './styles.module.css';

import {
  name,
  email,
  phone,
  button,
  message,
  region,
  regions,
} from './data.json';

const Label = props => (
  <Label1 className={styles.label}>{props.children}</Label1>
);

export default props => (
  <Formik
    {...options.standard}
    initialValues={{
      email: '',
      phone: '',
      name: '',
      message: '',
      region: regions.moscow,
    }}
    onSubmit={({ message, ...rest }, { resetForm }) => {
      const values = message.trim().length > 0 ? { message, ...rest } : rest;
      props.onSubmit(values);
      if (props.onSuccess) props.onSuccess();
      resetForm();
    }}
    validationSchema={Yup.object({
      name: Yup.mixed().required(),
      email: Yup.string()
        .email()
        .required(),
      phone: Yup.string()
        .phone()
        .required(),
      region: Yup.string(),
      message: Yup.string(),
    })}
  >
    {props => {
      const { errors, handleSubmit } = props;
      const assignProps = createPropsFunc(props);

      const ErrorMessage = ({ name }) => (
        <ErrorMessage2 isHidden={!errors[name]}>{errors[name]}</ErrorMessage2>
      );

      return (
        <Form
          className={styles.form}
          onSubmit={handleSubmit}
          noValidate="novalidate"
        >
          <FieldWrapper>
            <Label>{name.label}</Label>
            <Name {...assignProps('name')} autoFocus={true} />
            <ErrorMessage name={'name'} />
          </FieldWrapper>

          <FieldWrapper>
            <Label>{email.label}</Label>
            <Email {...assignProps('email')} />
            <ErrorMessage name={'email'} />
          </FieldWrapper>

          <FieldWrapper>
            <Label>{phone.label}</Label>
            <Phone {...assignProps('phone')} />
            <ErrorMessage name={'phone'} />
          </FieldWrapper>

          <FieldWrapper>
            <Label>{region.label}</Label>
            <Region className={styles.select} {...assignProps('region')}>
              {Object.values(regions).map(value => (
                <option key={value} value={value}>
                  {value}
                </option>
              ))}
            </Region>
          </FieldWrapper>

          <FieldWrapper>
            <Label>{message.label}</Label>
            <Message {...assignProps('message')} className={styles.message} />
            <ErrorMessage name={'message'} />
          </FieldWrapper>

          <Submit className={styles.submit}>{button.value}</Submit>
        </Form>
      );
    }}
  </Formik>
);
